import {
  Directive,
  Optional,
  ComponentRef,
  ViewContainerRef,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  NgZone,
} from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { GridErrorPanelComponent } from '../components';
import { GridLoadingDirective } from './grid-loading.directive';
import { GridErrors } from '../models/grid-errors.model';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[cueGridError]',
  standalone: true,
})
export class GridErrorDirective implements AfterViewInit, OnDestroy {
  initialized = false;
  // tslint:disable-next-line: variable-name
  _loading: GridErrors = null;
  // tslint:disable-next-line: variable-name
  _panelComponent: ComponentRef<GridErrorPanelComponent> = null;

  @Input('cueGridError')
  set loading(value: GridErrors) {
    if (this.initialized) {
      if (this._loading !== value) {
        this.doLoading(value);
      }
    }
    this._loading = value;
  }

  doLoading(value: GridErrors) {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const foundEleme = this.kendoGrid.wrapper.nativeElement
          .getElementsByTagName('kendo-grid-list')[0]
          .getElementsByTagName('app-grid-error-panel')[0];
        if (value && (!this.gridLoading || !this.gridLoading.loading)) {
          if (!this._panelComponent) {
            this._panelComponent = this.viewContainer.createComponent(GridErrorPanelComponent);
          }
          this._panelComponent.instance.errorCode = value.errorCode;
          this._panelComponent.instance.errorDescription = value.errorDescription;
          this._panelComponent.changeDetectorRef.detectChanges();

          const domElem = this._panelComponent.location.nativeElement;

          if (!foundEleme) {
            this.kendoGrid.wrapper.nativeElement.getElementsByTagName('kendo-grid-list')[0].appendChild(domElem);
          }
        } else {
          if (foundEleme) {
            this.kendoGrid.wrapper.nativeElement.getElementsByTagName('kendo-grid-list')[0].removeChild(foundEleme);
          }
        }
        this.cdr.markForCheck();
      }, 1);
    });
  }

  constructor(
    @Optional() private kendoGrid: GridComponent,
    @Optional() private gridLoading: GridLoadingDirective,
    private viewContainer: ViewContainerRef,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
  ) {
    if (kendoGrid === undefined) {
      throw Error('cueGridLoading has to be used on kendo-grid element!');
    }
  }

  ngOnDestroy(): void {
    if (this._panelComponent) {
      this._panelComponent.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.initialized = true;
    this.doLoading(this._loading);
  }
}
