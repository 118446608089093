/* src/app/grid/components/grid-loading-panel/grid-loading-panel.component.scss */
:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #000000;
  background: white;
}
div {
  height: 100%;
}
.centerer {
  align-items: center;
  display: flex;
}
.testLoader {
  display: inline-block;
  height: 26px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.testSuccess,
.sendEmailSuccess {
  color: #009200;
  font-weight: bold;
}
.testError,
.sendEmailError {
  color: #a52a2a;
  font-weight: bold;
}
.testLoaderTd {
  width: 1px;
  vertical-align: bottom;
}
.sendEmailTable {
  margin-top: 14px;
}
.sendEmailInfo {
  margin-top: 14px;
}
.sendEmailText {
  margin-left: 10px;
}
.sendEmailLoader {
  margin-left: 14px;
  display: inline-block;
  height: 26px;
}
span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  padding-top: 5px;
}
/*# sourceMappingURL=grid-loading-panel.component.css.map */
