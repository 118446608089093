import { Component, OnInit } from '@angular/core';
import { LoaderComponent } from '@cue/admin-shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-grid-loading-panel',
  templateUrl: './grid-loading-panel.component.html',
  styleUrls: ['./grid-loading-panel.component.scss'],
  standalone: true,
  imports: [LoaderComponent, TranslateModule],
})
export class GridLoadingPanelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
