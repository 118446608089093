import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-grid-error-panel',
  templateUrl: './grid-error-panel.component.html',
  styleUrls: ['./grid-error-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class GridErrorPanelComponent implements OnInit {
  @Input() errorCode: number = null;
  @Input() errorDescription = '';

  constructor() {}

  ngOnInit() {}
}
