/* src/app/grid/components/grid-error-panel/grid-error-panel.component.scss */
:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #000000;
  background: white;
}
div {
  height: 100%;
  color: #a52a2a;
  padding-top: 10px;
}
/*# sourceMappingURL=grid-error-panel.component.css.map */
